import { useEffect } from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import BackgroundDecor from '../components/BackgroundDecor';
import Spoiler from '../components/Spoiler';
import Title from '../components/Title';
import VacanciesList from '../components/VacanciesList';
import VacanciesOffer from '../components/VacanciesOffer';
import useVacancies from '../services/service.vacancies';
import { useDispatch } from 'react-redux';
import { setVacancies } from '../store/slices/vacanciesSlice';
import useStore from '../store/selectors/useStore';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function Vacancies() {
	const { t } = useTranslation();
	useDocumentTitle(t('vacancies.title'));

	const {
		getAllVacancies
	} = useVacancies();

	const dispatch = useDispatch();
	const { vacancies, language } = useStore();

	const openSpoiler = (event: any) => {
		if (event.target?.parentNode.classList.contains('open')) {
			event.target?.parentNode.classList.remove('open');
		} else {
			event.target?.parentNode.classList.add('open');
		}
	};

	useEffect(() => {
		if(language) {
			getAllVacancies(language, 'htp').then((data) =>{
				dispatch(setVacancies(data.vacancies))
			});
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<div className="vacancies">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="vacancies__body">
					<BackgroundDecor
						amountOfElements={16}
						className="vacancies__background-decor top-right"
					/>
					<BackgroundDecor
						amountOfElements={16}
						className="vacancies__background-decor center-right"
					/>
					<BackgroundDecor
						amountOfElements={16}
						className="vacancies__background-decor bottom-left"
					/>
					<Title size="middle" className="vacancies__title">
						{t('vacancies.title')}
					</Title>
					<h4 className="vacancies__appeal">
						{t('vacancies.header')}
						<span className="company">{t('vacancies.company')}</span>:
					</h4>
					<VacanciesList data={vacancies} setCurrentVacancy={()=>{}} />
					<div className="vacancies__spoilers">
						<Spoiler
							key={1}
							className="vacancies__spoiler"
							onClick={openSpoiler}
							workspace="mcii"
						>
							{t('vacancies.spoilers.1.first')}
							<span className="company">
								{t('vacancies.spoilers.1.second')}
							</span>
							{t('vacancies.spoilers.1.third')}
						</Spoiler>
						<Spoiler
							key={2}
							className="vacancies__spoiler"
							onClick={openSpoiler}
							workspace="uhp"
						>
							{t('vacancies.spoilers.2.first')}
							<span className="company">
								{t('vacancies.spoilers.2.second')}
							</span>
							{t('vacancies.spoilers.2.third')}
						</Spoiler>
						<Spoiler
							key={3}
							className="vacancies__spoiler"
							onClick={openSpoiler}
							workspace="uaep"
						>
							{t('vacancies.spoilers.3.first')}
							<span className="company">
								{t('vacancies.spoilers.3.second')}
							</span>
							{t('vacancies.spoilers.3.third')}
						</Spoiler>
					</div>
					<VacanciesOffer />
				</div>
			</div>
		</div>
	);
}

export default Vacancies;
