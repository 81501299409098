import React from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import MainAbout from '../components/MainAbout';
import MainManagement from '../components/MainManagement';
import MainMap from '../components/MainMap';
import MainNews from '../components/MainNews';
import MainProjects from '../components/MainProjects';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function Main() {
	const { t } = useTranslation();
	useDocumentTitle(`${t('main.title.type')} ${t('main.title.name')}`);

	return (
		<div className="main">
			<ScrollToTopOnMount />
			<div className="main__body">
				<MainAbout />
				<MainProjects />
				<MainNews />
				<MainMap />
				<MainManagement />
			</div>
		</div>
	);
}

export default Main;
