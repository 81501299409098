import React, { useState } from 'react';
import useRedirectToItem from '../hooks/useRedirectToItem';
import BackgroundDecor from './BackgroundDecor';
import ButtonLink from './ButtonLink';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function MainAboutTabs() {
	const redirect = useRedirectToItem();
	const [toggleState, setToggleState] = useState(0);

	const { t } = useTranslation();

	const translateTabs = t('main.about-tabs.tabs', {
		returnObjects: true,
	});

	return (
		<div className="main-about-tabs">
			<BackgroundDecor
				amountOfElements={16}
				className="main-about-tabs__background-decor top-left"
			/>
			<BackgroundDecor
				amountOfElements={16}
				className="main-about-tabs__background-decor bottom-right"
			/>
			<div className="main-about-tabs__body">
				<div className="main-about-tabs__nav">
					{Array.from(translateTabs).map((tab: any, index: number) => {
						return (
							<button
								key={index}
								onClick={() => setToggleState(index)}
								className={
									toggleState === index
										? 'main-about-tabs__nav-button active'
										: 'main-about-tabs__nav-button'
								}
							>
								<span>{tab.title}</span>
							</button>
						);
					})}
				</div>

				<ul className="main-about-tabs__content">
					{Array.from(translateTabs).map((tab: any, index: number) => (
						<li
							key={index}
							className={
								toggleState === index
									? 'main-about-tabs__content-item active'
									: 'main-about-tabs__content-item'
							}
						>
							<div className="main-about-tabs__content-text">
								<p>{tab.p1}</p>
								<p>{tab.p2}</p>
							</div>
							<ButtonLink
								type="button"
								color="blue"
								className={
									index === 2
										? 'main-about-tabs__content-button hide'
										: 'main-about-tabs__content-button'
								}
								onClick={() =>
									index === 0 ? redirect('about') : redirect('services')
								}
							>
								{t('main.buttons.read-more')}
							</ButtonLink>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default MainAboutTabs;
