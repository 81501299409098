import React, { useRef } from 'react';
import { useOnClickOutside } from '../hooks';
import BurgerButton from './BurgerButton';
import ContactButton from './ContactButton';
import ContactDropdown from './ContactDropdown';

import { setIsOpenContactDropdown } from '../store/slices/contactDropdownSlice';
import useBindActionCreators from '../hooks/useBindActionCreators';
import useStore from '../store/selectors/useStore';

interface propContact {
	className?: string;
}

function Contact({ className }: propContact) {
	const { contactDropdown } = useStore();
	const boundCreators = useBindActionCreators({
		setIsOpenContactDropdown,
	});
	const { setIsOpenContactDropdown: dispatchIsOpenContactDropdown } =
		boundCreators;

	const refConctactDropdown = useRef<HTMLDivElement>(null);

	const handleOpenDropdown = () => {
		dispatchIsOpenContactDropdown(true);
		if (window.innerWidth < 991) {
			document.body.style.overflow = 'hidden';
		}
	};

	const handleCloseDropdown = () => {
		dispatchIsOpenContactDropdown(false);
		document.body.style.overflow = 'unset';
	};

	useOnClickOutside(refConctactDropdown, handleCloseDropdown);

	return (
		<div ref={refConctactDropdown} className={`contact ${className}`}>
			<div className="contact__body">
				<ContactButton
					className="contact__button"
					openDropdown={handleOpenDropdown}
				/>
				<BurgerButton
					className="contact__burger-button"
					openDropdown={handleOpenDropdown}
					closeDropdown={handleCloseDropdown}
					isOpenDropdown={contactDropdown}
				/>
				<ContactDropdown
					className="contact__dropdown"
					isOpenDropdown={contactDropdown}
					closeDropdown={handleCloseDropdown}
				/>
			</div>
		</div>
	);
}

export default Contact;
