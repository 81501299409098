import React, { ReactNode } from 'react';
import sprite from '../img/icons/spriteServices.svg';

interface propsServicesCard {
	children?: ReactNode,
	icon?: string,
	size: 'big' | 'middle',
}

function ServicesCard({ children, icon, size }: propsServicesCard) {
	return (
		<li className={`services-card services-card--${size}`}>
			<div className="services-card__body">
				<svg className="services-card__icon">
					<use href={sprite + '#' + icon} />
				</svg>
				<div className="services-card__text">
					{children}
				</div>
			</div>
		</li>
	);
}

export default ServicesCard;
