import React, { useState } from 'react';
import HeaderNavigation from './HeaderNavigation';
import Contact from './Contact';
import Search from './Search';
import LogoDropdown from './LogoDropdown';
import HeaderLanguage from './HeaderLanguage';

function Header() {

	const [isFocus, setIsFocus] = useState(false);

	return (
		<header className="header">
			<div className="container">
				<div className="header__body">
					<div className="header__logo">
						<LogoDropdown />
					</div>
					<HeaderNavigation
						isFocus={isFocus}
						className="header__navigation"
						closeDropdown={() => { }}
					/>
					<Search
						setFocus={setIsFocus}
						className={isFocus ? "header__search focus" : "header__search"}
					/>
					<HeaderLanguage className="header__language" />
					<Contact className="header__contact" />
				</div>
			</div>
		</header>
	);
}

export default Header;
