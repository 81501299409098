/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { useNavigate, useParams } from 'react-router-dom';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import BackgroundDecor from '../components/BackgroundDecor';
import Title from '../components/Title';
import useNews from '../services/service.news';
import useStore from '../store/selectors/useStore';
import '../utils/i18next';
import { useDispatch } from 'react-redux';
import { imageSrc } from '../helpers/imageSrc';
import { setTitle } from '../store/slices/titleSlice';

function NewsInside() {
	const { language } = useStore();
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`;
	const { getNews } = useNews();
	const { id: newsId } = useParams();
	const [currentNews, setCurrentNews] = useState<any>();
	const dispatch = useDispatch();
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const openImageViewer = useCallback((index: number) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};
	const navigate = useNavigate();

	let translationSlug;

	let checkIfLanguageCreated = (currentNews: {
		article: { translations: any[] };
	}) => {
		return currentNews.article?.translations?.some(
			(el: { language: { code: string } }) => el.language.code === language
		);
	};
	useEffect(() => {
		if (newsId && !currentNews) {
			getNews(newsId, language).then((news) => {
				setCurrentNews(news);
				dispatch(setTitle(news.title));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			dispatch(setTitle(''));
		};
	}, []);

	useEffect(() => {
		if (
			language !== currentNews?.language?.code &&
			currentNews &&
			checkIfLanguageCreated(currentNews)
		) {
			translationSlug = currentNews?.article?.translations?.filter(
				(el: { language: { code: string }; slug: string }) => {
					if (el.language.code === language) {
						return el;
					}
				}
			);
			getNews(
				`${
					translationSlug[0]?.slug ? translationSlug[0]?.slug : currentNews.slug
				}`,
				language
			).then((news) => {
				dispatch(setTitle(news.title));
				setCurrentNews(news);
			});
			navigate(
				`/news/${
					translationSlug[0]?.slug ? translationSlug[0]?.slug : currentNews.slug
				}`,
				{ replace: true }
			);
		}
	}, [
		currentNews?.article?.translations,
		currentNews?.language?.code,
		language,
		translationSlug,
	]);

	return (
		<section className="news-inside">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="news-inside__body">
					<BackgroundDecor
						amountOfElements={16}
						className="news-inside__background-decor top-right"
					/>
					<Title size="middle" className="news-inside__title">
						{currentNews?.title}
					</Title>
					<span className="news-list__date news-inside__date">
						{currentNews?.article?.updatedAt
							.slice(0, 10)
							.split('-')
							.reverse()
							.join('-')
							.replaceAll('-', '.')}
					</span>
					<div
						className="news-inside__image"
						onClick={() => openImageViewer(0)}
					>
						{currentNews && (
							<img src={`${imageSrc(currentNews)}`} alt={currentNews?.title} />
						)}
					</div>
					{isViewerOpen && (
						<ImageViewer
							src={[`${_baseUrl}${currentNews?.image?.large.path}`]}
							currentIndex={currentImage}
							disableScroll={true}
							closeOnClickOutside={true}
							onClose={closeImageViewer}
						/>
					)}
					<div
						className="news-inside-content"
						contentEditable="false"
						dangerouslySetInnerHTML={{
							__html: currentNews?.body,
						}}
					/>
					<BackgroundDecor
						amountOfElements={16}
						className="news-inside__background-decor center-left"
					/>
				</div>
			</div>
		</section>
	);
}

export default NewsInside;
