import { createSlice } from '@reduxjs/toolkit';
import { contactDropdownState } from '../../typings/contactDropdown';

const initialState: contactDropdownState = {
	isOpen: false,
};

const contactDropdownSlice = createSlice({
	name: 'contactDropdown',
	initialState,
	reducers: {
		setIsOpenContactDropdown: (state, action) => {
			state.isOpen = action.payload;
		},
	},
});

const { actions, reducer: contactDropdownReducer } = contactDropdownSlice;
export const { setIsOpenContactDropdown } = actions;
export default contactDropdownReducer;
