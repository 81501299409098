import React from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Title from '../components/Title';
import ServicesProjects from '../components/ServicesProjects';
import ServicesEngineering from '../components/ServicesEngineering';
import BackgroundDecor from '../components/BackgroundDecor';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function Services() {
	const { t } = useTranslation();
	useDocumentTitle(t('services.title'));

	return (
		<div className="services">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="services__body">
					<BackgroundDecor
						amountOfElements={16}
						className="services__background-decor top-right"
					/>
					<Title size="middle" className="services__title">
						{t('services.title')}
					</Title>
					<div
						className="services__info"
						contentEditable="false"
						dangerouslySetInnerHTML={{
							__html: t('services.desc'),
						}}
					/>
					<ServicesProjects className="services__projects" />
					<ServicesEngineering />
					<BackgroundDecor
						amountOfElements={16}
						className="services__background-decor bottom-left"
					/>
				</div>
			</div>
		</div>
	);
}

export default Services;
