import { useEffect, useState } from 'react';
import ButtonLink from './ButtonLink';
import { useResizeObserver } from '../hooks/useResizeObserver';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';

const seeMore = {
	name: 'seeMore',
	name_ua: 'Переглянути більше',
	name_en: 'See more',
	name_ru: 'Смотреть больше',
};

const closeMore = {
	name: 'closeMore',
	name_ua: 'Згорнути',
	name_en: 'Collapse',
	name_ru: 'Свернуть',
};

interface propsMoreControls {
	handlerRef: any;
	openedItems: any;
	handleOpenedItems: any;
	items: any;
	handleBlockHeight: any;
}

function MoreControls({
	handlerRef,
	openedItems,
	handleOpenedItems,
	items,
	handleBlockHeight,
}: propsMoreControls) {
	const { language } = useStore();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const [dimensions, setDimensions] = useState({ top: 0, left: 0 });
	const optionalCallback = (entry: DOMRectReadOnly) =>
		setDimensions({ top: entry.x, left: entry.left });
	const [width, height] = useResizeObserver(handlerRef, optionalCallback);

	const handleOnClickMore = () => {
		if (openedItems) {
			if (openedItems?.length + 4 < items.length) {
				handleOpenedItems(items.slice(0, openedItems?.length + 4));
			} else {
				handleOpenedItems(items);
			}

			if (!isOpen) {
				setIsOpen(true);
			}
		}
	};

	const handleOnClickClose = () => {
		handleOpenedItems(items.length >= 4 ? items.slice(0, 4) : items);
		setIsOpen(false);
	};

	useEffect(() => {
		handleBlockHeight(height);
	}, [height]);

	return (
		<div className="about-file__controlls">
			{openedItems?.length !== items.length && (
				<ButtonLink
					className="about-file__controll"
					color="blue"
					type="button"
					onClick={handleOnClickMore}
				>
					{getLocaleProperty(seeMore, 'name', language)}
				</ButtonLink>
			)}
			<ButtonLink
				className="about-file__controll"
				color="blue"
				type="button"
				onClick={handleOnClickClose}
			>
				{isOpen && getLocaleProperty(closeMore, 'name', language)}
			</ButtonLink>
		</div>
	);
}

export default MoreControls;
