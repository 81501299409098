import { createSlice } from "@reduxjs/toolkit";
import { projectsState } from "../../typings/projects";

const initialState: projectsState = {
    projects: [],
    filteredProjects: []
};

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjects: (state, action) => {
            state.projects = action.payload;
            state.filteredProjects = action.payload;
        },
        filterProjectsByCategory: (state, { payload }) => {
            state.filteredProjects = state.projects.filter(project => {
                return project?.project?.category?.translation?.name === payload.name
            });
        }
    }
});

const { actions, reducer: projectsReducer } = projectsSlice;
export const { setProjects, filterProjectsByCategory } = actions;
export default projectsReducer;