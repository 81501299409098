import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { NEWS, SERVICES, VACANCIES, ABOUT } from '../helpers/routes';
import { scrollToAnchor } from '../helpers/scrollToAnchor';
import { setIsOpenContactDropdown as callToAction } from '../store/slices/contactDropdownSlice';
import useBindActionCreators from '../hooks/useBindActionCreators';
import useStore from '../store/selectors/useStore';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';

const aboutLinks1 = [
	{
		name_ua: 'Керівництво',
		name_en: 'Management',
		name_ru: 'Руководство',
		path: `management`,
	},
	{
		name_ua: 'Структура',
		name_en: 'Structure',
		name_ru: 'Структура',
		path: `structure`,
	},
	{
		name_ua: 'Файли для завантаження',
		name_en: 'Downloads',
		name_ru: 'Файлы для скачивания',
		path: `downloads`,
	},
];

const aboutLinks2 = [
	{
		name_ua: 'Послуги',
		name_en: 'Services',
		name_ru: 'Услуги',
		path: SERVICES,
	},
	{
		name_ua: 'Новини',
		name_en: 'News',
		name_ru: 'Новости',
		path: NEWS,
	},
	{
		name_ua: 'Вакансії',
		name_en: 'Career',
		name_ru: 'Вакансии',
		path: VACANCIES,
	},
];

interface propsFooterAbout {
	className?: string;
	children?: ReactNode;
}

function FooterAbout({ className, children }: propsFooterAbout) {
	const boundCreators = useBindActionCreators({
		callToAction,
	});

	const { callToAction: dispatchIsOpenContactDropdown } = boundCreators;

	const { language } = useStore();

	const handleClick = (path: string) => {
		scrollToAnchor(path);
	};

	return (
		<div className={`footer-about ${className}`}>
			<div className="footer-about__body">
				{children}
				<ul className="footer-about__list">
					{aboutLinks1.map((item, index) => (
						<li key={index} className="footer-about__item">
							<Link
								to={ABOUT}
								className="footer-about__link"
								onClick={() => {
									handleClick(item.path);
								}}
							>
								{getLocaleProperty(item, 'name', language)}
							</Link>
						</li>
					))}
				</ul>
				<ul className="footer-about__list">
					{aboutLinks2.map((item, index) => (
						<li key={index} className="footer-about__item">
							{item.path !== '/' ? (
								<Link
									to={item.path}
									className="footer-about__link"
									onClick={() => {
										handleClick(item.path);
									}}
								>
									{getLocaleProperty(item, 'name', language)}
								</Link>
							) : (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									id="open-contact"
									className="footer-about__link"
									onClick={() => {
										setTimeout(() => {
											dispatchIsOpenContactDropdown(true);
										}, 0);
									}}
								>
									{getLocaleProperty(item, 'name', language)}
								</a>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default FooterAbout;
