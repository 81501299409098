import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import ImageViewer from 'react-simple-image-viewer';
import { imageSrc } from '../helpers/imageSrc';

interface propsSlider {
	data: {
		priority: string;
		image: {
		  original: {
			path: string;
		  };
		  small: {
			path: string;
		  };
		  medium: {
			path: string;
		  };
		  large: {
			path: string;
		  };
		};
		isEnabled: string;
	  }[],
	className?: string,
	swiperClassName?: string,
	buttonSize: 'big' | 'middle',
}

const settings = {
	slidesPerView: 1,
	centeredSlides: true,
	loop: true,
	speed: 500,
	pagination: {
		clickable: true,
		bulletElement: "slider__bullet",
		bulletClass: "slider__bullet",
		bulletActiveClass: "slider__bullet--active",
		clickableClass: "slider__pagination",
	},
	navigation: {
		nextEl: '.slider__button-next',
		prevEl: '.slider__button-prev',
	}
}

function Slider({ data, className, swiperClassName, buttonSize }: propsSlider) {

	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState([])
	const [currentSrc, setCurrentSrc] = useState(['']);
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`
	const openImageViewer = useCallback((index, src) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
		setCurrentSrc([src])
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	useEffect(()=>{
		if(data) {
			let imagesArr:string[] = []
			data.map((image) => {
				imagesArr.push(`${imageSrc(image)}`)
			})
			setImages(imagesArr as any)
		}
	}, [data])

	return (
		<div
			className={`slider ${className}`}
		>
			<Swiper
				{...settings}
				modules={[Navigation, Pagination, Autoplay]}
				className={`slider__swiper ${swiperClassName}`}
			>
				{data.map((image, index) => (
					image?.image?.original?.path ? <SwiperSlide key={index}>
					<img
						src={`${imageSrc(image, true)}`}
						alt="document"
						className="slider__image"
						onClick={() => 
							openImageViewer(index, `${_baseUrl}${image?.image?.original?.path}`)}
					/>
				</SwiperSlide> 
				:
					<SwiperSlide key={index}>
						<img
							src={`${imageSrc(image)}`}
							alt="document"
							className="slider__image"
							onClick={() => 
								openImageViewer(index, `${_baseUrl}${image?.image?.large?.path}`)}
						/>
					</SwiperSlide>
				))}
			</Swiper>

			<button
				type="button"
				className={`slider__button-prev slider__button-prev--${buttonSize}`}
			/>
			<button
				type="button"
				className={`slider__button-next slider__button-next--${buttonSize}`}
			/>
			{isViewerOpen && (
				<ImageViewer
					src={images}
					currentIndex={currentImage}
					disableScroll={true}
					closeOnClickOutside={true}
					onClose={closeImageViewer}
				/>
			)}
		</div>
	);
}

export default Slider;
