import useHttp from './httpService';

const useCountries = () => {
	const { getAll100 } = useHttp();
	const _baseUrl = `${process.env.REACT_APP_API_URL}`;
	const getAllCountries = (language = 'ua') => getAll100('country', 'GET',
	null,
	{'Accept-Language': `${language}`, 
	'X-Workspace': 'htp',
	'Access-Control-Allow-Origin': `${_baseUrl}`});
	return getAllCountries;
};

export default useCountries;
