import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCube } from 'swiper';
import mainAboutSlide1 from '../img/mainAboutSlides/mainAboutSlide1.jpg';
import mainAboutSlide2 from '../img/mainAboutSlides/mainAboutSlide2.jpg';
import mainAboutSlide3 from '../img/mainAboutSlides/mainAboutSlide3.jpg';
import BackgroundDecor from './BackgroundDecor';

function MainAboutSlider() {
	return (
		<div className="main-about-slider">
			<div className="main-about-slider__body">
				<BackgroundDecor
					amountOfElements={48}
					className="main-about-slider__background-decor"
				/>
				<Swiper
					className="main-about-slider__swiper"
					modules={[Navigation, Pagination, Autoplay, EffectCube]}
					effect="cube"
					cubeEffect={{
						shadow: true,
						shadowOffset: 3,
					}}
					slidesPerView={1}
					centeredSlides={true}
					loop={true}
					speed={1500}
					autoplay={{
						delay: 5000,
					}}
					pagination={{
						clickable: true,
						bulletClass: 'main-about-slider__bullet',
						bulletActiveClass: 'main-about-slider__bullet--active',
						clickableClass: 'main-about-slider__pagination',
					}}
				>
					<SwiperSlide>
						<img
							src={mainAboutSlide2}
							alt=""
							className="main-about-slider__image"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={mainAboutSlide3}
							alt=""
							className="main-about-slider__image"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={mainAboutSlide1}
							alt=""
							className="main-about-slider__image"
						/>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
	);
}

export default MainAboutSlider;
