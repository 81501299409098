import { createSlice } from "@reduxjs/toolkit";
import { vacancies } from "../../typings/vacancies";

const initialState: vacancies = {
    pagination: {
        total: 0,
        currentPage: 0,
        lastPage: 0
    },
    vacancies: [],
};



const vacanciesSlice = createSlice({
    name: 'vacancies',
    initialState,
    reducers: {
        setVacancies: (state, action) => {
            state.vacancies = action.payload;
        }
    }
});


const { actions, reducer: vacanciesReducer } = vacanciesSlice;
export const { setVacancies } = actions;
export const VacanciesActions = actions;
export default vacanciesReducer;
