import { MutableRefObject, useRef } from "react";

const useFocus = (): [any, () => void] => {
	const inputRef: MutableRefObject<any> = useRef(null);
	const setFocus = (): void => {
		inputRef?.current?.focus?.();
	};

	return [inputRef, setFocus];
};

export { useFocus };
