/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Title from '../components/Title';
import VacanciesList from '../components/VacanciesList';
import useVacancies from '../services/service.vacancies';
import useStore from '../store/selectors/useStore';
import { setVacancies } from '../store/slices/vacanciesSlice';
import { vacancy } from '../typings/vacancies';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { setTitle } from '../store/slices/titleSlice';

function VacanciesInside() {
	const { t } = useTranslation();
	const { vacancies, language } = useStore();
	const { getVacancy, getAllVacancies } = useVacancies();
	const navigate = useNavigate()
	const { id: vacancyId } = useParams();

	const [currentVacancy, setCurrentVacancy] = useState<vacancy>({
		slug: '',
		title: '',
		body: '',
		image: {
			small: {
				path: ''
			},
			medium: {
				path: ''
			},
			large: {
				path: ''
			},
		},
		language: {
			code: 'ua'
		},
		vacancy: {
			translations: [
				{
					slug: "",
					"language": {
						"code": "ua"
					}
				},
			]
		}
	});
	const dispatch = useDispatch();
	  
	useEffect(() => {
		if (vacancyId  && language && !currentVacancy.slug ) {
			getVacancy(vacancyId as string, language).then((data) => {
				setCurrentVacancy(data)
				dispatch(setTitle(data.title))
			});
		}
	}, [language, vacancyId]);

	useEffect(() => {
		return () => {
			dispatch(setTitle(""))
		}
	}, [])
	useEffect(() => {
		if (language && (vacancies.length === 0 || vacancies[0].language.code !== language)) {
			getAllVacancies(language, 'htp').then((data) => {
				dispatch(setVacancies(data.vacancies))
			}
			);
		}
	}, [language]);

	

	let vacanciesExceptCurrent = vacancies
	.filter((item, index) => item.slug.toString() !== vacancyId && item.language.code === language && index <= 5);


	let checkIfLanguageCreated = (currentVacancy : vacancy) => {
		return currentVacancy.vacancy?.translations?.some((el: { language: { code: string; }; }) => 
			el.language.code === language)
	}	

	useEffect(() => {
		if((language !== currentVacancy?.language?.code && currentVacancy && checkIfLanguageCreated(currentVacancy))) {
			let translationSlug = currentVacancy?.vacancy?.translations?.filter((el: { language: { code: string; }, slug: string }) => {
				if (el.language.code === language) {
					return el
				}
			})

				getVacancy(`${translationSlug[0]?.slug ? translationSlug[0]?.slug : currentVacancy.slug }`, language).then((data) => {
					setCurrentVacancy(data)
					dispatch(setTitle(data.title))
				})

			navigate(`/vacancies/${translationSlug[0]?.slug ? translationSlug[0]?.slug : currentVacancy.slug }`, { replace: true })
		}
		
	}, [getVacancy, language]);

	return (
		<section className="vacancies-inside">
			<ScrollToTopOnMount />
			<div className="container">
				<Title size="middle" className="vacancies-inside__title">
					{currentVacancy.title}
				</Title>
				<div className="vacancies-inside__body">
					<div className="vacancies-inside__content">
						<div
							className="vacancies-inside__content"
							contentEditable="false"
							dangerouslySetInnerHTML={{
								__html: currentVacancy.body,
							}}
						/>
					</div>

					<aside className="vacancies-inside__aside">
						<Title size="small" className="vacancies-inside__aside-title">
							{t('vacancies.other-vacancies')}
						</Title>
						<VacanciesList
							data={vacanciesExceptCurrent}
							setCurrentVacancy={setCurrentVacancy}
							className="vacancies-inside__vacancies-list vacancies-list--aside"
						/>
					</aside>
				</div>
			</div>
		</section>
	);
}

export default VacanciesInside;
