/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PROJECTS } from '../helpers/routes';
import Title from './Title';
import ButtonLink from './ButtonLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import useProjects from '../services/service.projects';
import useStore from '../store/selectors/useStore';
import useRedirectToItem from '../hooks/useRedirectToItem';
import projectEmptyPhoto from '../img/projects/project-empty-photo.png';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { imageSrcProject } from '../helpers/imageSrc';
import {project as Project} from '../../src/typings/projects'
import {projects as Projects} from '../../src/typings/projects'
const addBeforeZero = (number: any) => {
	if (number < 10) {
		number = '0' + number;
	}
	return number;
};

function MainProjects() {
	const { t } = useTranslation();
	const { language } = useStore();

	const { getAllProjects } = useProjects();
	const { projects } = useStore();
	const [mainProjects, setMainProjects] = useState<Projects>([])
	const redirect = useRedirectToItem();
	const [currentSlide, setCurrentSlide] = useState(Number);


	useEffect(() => {
		getAllProjects(language).then((data) => {
			const dniproHPS = data.projects.find((project :Project) => project.project.id === "63") || {}
			const dnistroPSH = data.projects.find((project :Project) => project.project.id === "9") || {}
			const tashlytskaPSH = data.projects.find((project :Project) => project.project.id === "5") || {}
			const GERD = data.projects.find((project :Project) => project.project.id === "15") || {}
			const mtkvariHPS =  data.projects.find((project :Project) => project.project.id === "49") || {}
			setMainProjects([dniproHPS,dnistroPSH,tashlytskaPSH, GERD, mtkvariHPS])
		}
		);
	}, [language]);

	return (
		<section className="main-projects">
			{mainProjects?.map((project, index) => (
				
				<img
					key={index}
					src={project?.images?.length ? imageSrcProject(project) : projectEmptyPhoto}
					alt=""
					className={
						index === currentSlide
							? 'main-projects__background-image active'
							: 'main-projects__background-image'
					}
				/>
			))}
			<div className="container">
				<div className="main-projects__wrapper">
					<div className="main-projects__header">
						<Title size="big" className="main-projects__title">
							{t('main.projects.title')}
						</Title>
						<Link
							to={PROJECTS}
							className="main-projects__button button-link button-link--white"
						>
							{t('main.projects.see-all')}
						</Link>
					</div>

					<div className="main-projects__body">
						<div className="main-projects__slider">
							<Swiper
								modules={[Navigation, Autoplay, Pagination]}
								className="main-projects__swiper"
								initialSlide={1}
								autoHeight={false}
								slidesPerView={1}
								allowTouchMove={false}
								slideToClickedSlide={true}
								spaceBetween={30}
								centeredSlides={true}
								loop={false}
								speed={1100}
								longSwipes={false}
								autoplay={{
									delay: 2000,
								}}
								navigation={{
									nextEl: '.main-projects__button-next',
									prevEl: '.main-projects__button-prev',
								}}
								pagination={{
									type: 'fraction',
									el: '.main-projects__slide-total-body',
									currentClass: 'main-projects__slide-current',
									totalClass: 'main-projects__slide-all',
									formatFractionCurrent: addBeforeZero,
									formatFractionTotal: addBeforeZero,
								}}
								onSlideChange={(index) => {
									setCurrentSlide(index.activeIndex);
								}}
								breakpoints={{
									1367: {
										slidesPerView: 3,
									},
									646: {
										slidesPerView: 2,
									},
								}}
							>
								{mainProjects?.map((project, index) => (
									<SwiperSlide
										key={index}
										className="main-projects__slide"
									>
										<img
											src={project?.images?.length ? imageSrcProject(project) : projectEmptyPhoto}
											alt=""
											className="main-projects__slide-image"
										/>
										<span className="main-projects__slide-country">
											{project?.project?.country?.translation?.name}
										</span>
										<h4 className="main-projects__slide-title">
											{project.title}
										</h4>
										<ButtonLink
											onClick={() => redirect(PROJECTS, project.slug as any)}
											type="button"
											color="white"
											className="main-projects__slide-button"
										>
											{t('main.buttons.to-project')}
										</ButtonLink>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>

					<div className="main-projects__footer">
						<div className="main-projects__background-decor"></div>
						<div className="main-projects__control">
							<button className="main-projects__button-prev"></button>
							<button className="main-projects__button-next"></button>
							<div className="main-projects__slide-total">
								<div className="main-projects__slide-total-body">
									<span className="main-projects__slide-current" />
									<span className="main-projects__slide-all" />
								</div>
							</div>
						</div>
					</div>
					<Link
						to={PROJECTS}
						className="main-projects__button button-link button-link--blue main-projects__button--mobile"
					>
						{t('main.projects.see-all')}
					</Link>
				</div>
			</div>
		</section>
	);
}

export default MainProjects;
