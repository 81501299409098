import React, { useState } from 'react';
import useRedirectToItem from '../hooks/useRedirectToItem';
import ButtonLink from './ButtonLink';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsMainAboutSpoilers {
	className?: string;
}

function MainAboutSpoilers({ className }: propsMainAboutSpoilers) {
	const redirect = useRedirectToItem();

	const [currentSpoiler, isCurrentSpoiler] = useState('');
	const openSpoiler = (name: string) => {
		if (currentSpoiler === name) {
			isCurrentSpoiler('');
		} else if (currentSpoiler !== name) {
			isCurrentSpoiler(name);
		}
	};

	const { t } = useTranslation();

	const translateTabs = t('main.about-tabs.tabs', {
		returnObjects: true,
	});

	return (
		<ul className={`main-about-spoilers ${className}`}>
			{Array.from(translateTabs).map((tab: any, index: number) => (
				<li
					key={index}
					className={
						currentSpoiler === 's' + index
							? `main-about-spoilers__item open ${className}`
							: `main-about-spoilers__item`
					}
				>
					<h6
						onClick={() => {
							openSpoiler('s' + index);
						}}
						className="main-about-spoilers__title"
					>
						{tab.title}:
						<span className="main-about-spoilers__title-arrow" />
					</h6>

					<div className="main-about-spoilers__content">
						<div className="main-about-spoilers__content-wrapper">
							<div className="main-about-spoilers__content-text">
								<p>{tab.p1}</p>
								<p>{tab.p2}</p>
							</div>
							<ButtonLink
								onClick={() =>
									index === 0 ? redirect('about') : redirect('services')
								}
								color="blue"
								className={
									index === 2
										? 'main-about-spoilers__content-button hide'
										: 'main-about-spoilers__content-button'
								}
							>
								{t('main.buttons.read-more')}
							</ButtonLink>
						</div>
					</div>
				</li>
			))}
		</ul>
	);
}

export default MainAboutSpoilers;
