import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, matchPath } from 'react-router-dom';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import Main from './pages/Main';
import About from './pages/About';
import Services from './pages/Services';
import Footer from './components/Footer';
import Projects from './pages/Projects';
import Search from './pages/Search';
import ReactGA from 'react-ga4';
import routes, {
	MAIN,
	ABOUT,
	NEWS,
	PROJECTS,
	SERVICES,
	VACANCIES,
	SEARCH,
} from './helpers/routes';
import ProjectInside from './pages/ProjectInside';
import News from './pages/News';
import Vacancies from './pages/Vacancies';
import NewsInside from './pages/NewsInside';
import VacanciesInside from './pages/VacanciesInside';
import NotFound from './components/NotFound';
import UpScroll from './components/UpScroll';
import { languageActions } from './store/slices/languageSlice';
import useBindActionCreators from './hooks/useBindActionCreators';
import './utils/i18next';
import i18n from 'i18next';

function App() {
	const boundCreators = useBindActionCreators({ ...languageActions });
	const { setLanguage } = boundCreators;

	let location = useLocation();
	let isMatchNotFound = routes.some((route) =>
		matchPath(route.path, location.pathname)
	);

	const trackingId = 'G-R4T12CH5Q7'
	ReactGA.initialize(trackingId)
	ReactGA.send({ hitType: "pageview", page: location.pathname, title: `${location.pathname} chapter` });

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const languageDetector = () => {
		let defaultLang = 'ua';
		const supportedLanguages = ['ua', 'en', 'ru'];

		let activeLanguage =
			i18n.language.indexOf('-') === -1
				? i18n.language
				: i18n.language.split('-')[0];
		if (activeLanguage === 'uk') {
			activeLanguage = 'ua';
		}

		if(location.pathname.includes('-en')) {
			setLanguage('en');
			activeLanguage = 'en'
			localStorage.setItem('i18nextLng', 'en');
		} 
		if (location.pathname.includes('-ru')) {
			setLanguage('ru');
			activeLanguage = 'ru'
			localStorage.setItem('i18nextLng', 'ru');
		} 
		if (location.pathname.includes('-ua')) {
			setLanguage('ua');
			activeLanguage = 'ua'
			localStorage.setItem('i18nextLng', 'ua');
		} 

		if (supportedLanguages.includes(activeLanguage)) {
			setLanguage(activeLanguage);
			localStorage.setItem('i18nextLng', activeLanguage);
			if(activeLanguage === 'ua') {
				document.documentElement.setAttribute('lang', 'uk');
			} else {
				document.documentElement.setAttribute('lang', activeLanguage);
			}
		}
		 else {
			setLanguage(defaultLang);
			localStorage.setItem('i18nextLng', defaultLang);
			document.documentElement.setAttribute('lang', defaultLang);
		}
	};

	useEffect(() => {
		languageDetector();
	}, [languageDetector]);

	return (
		<div className="App">
			<Suspense fallback={'Loading...'}>
				<Header />
				<main
					className={isMatchNotFound ? 'content' : 'content content--not-found'}
				>
					<UpScroll />
					{isMatchNotFound && <Breadcrumbs />}
					<Routes>
						<Route path={MAIN} element={<Main />} />
						<Route path={ABOUT} element={<About />} />
						<Route path={SERVICES} element={<Services />} />
						<Route path={PROJECTS} element={<Projects />} />
						<Route path={`${PROJECTS}/:id`} element={<ProjectInside />} />
						<Route path={NEWS} element={<News />} />
						<Route path={`${NEWS}/:id`} element={<NewsInside />} />
						<Route path={VACANCIES} element={<Vacancies />} />
						<Route path={`${VACANCIES}/:id`} element={<VacanciesInside />} />
						<Route path="/*" element={<NotFound />} />
						<Route path={SEARCH} element={<Search />} />
					</Routes>
				</main>
				{isMatchNotFound && <Footer />}
			</Suspense>
		</div>
	);
}

export default App;
