import React, { useEffect, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import useCategories from '../services/service.categories';
import useBindActionCreators from '../hooks/useBindActionCreators';
import { categoriesActions } from '../store/slices/categoriesSlice';
import { category } from '../typings/categories';
import useStore from '../store/selectors/useStore';
import { PROJECTS } from '../helpers/routes';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';

interface propsFooterProject {
	className?: string;
	children?: ReactNode;
}

function FooterProject({ className, children }: propsFooterProject) {
	const { categories, language } = useStore();
	const getAllCategories = useCategories();
	const { setCategories, setActiveCategory } =
		useBindActionCreators(categoriesActions);

	useEffect(() => {
		if(language) {
			getAllCategories(language).then((categories) => {
				setCategories(categories)
			});
		}
	
	}, [language]);

	return (
		<div className={`footer-project ${className}`}>
			<div className="footer-project__body">
				{children}

				<ul className="footer-project__list">
					{categories?.categories?.map((category) => (
						<li key={category.id} className="footer-project__item">
							<Link
								to={PROJECTS}
								onClick={() => {
									setActiveCategory(category);
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}
								className="footer-project__link"
							>
								{category.name}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default FooterProject;
