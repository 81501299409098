import React from 'react';
import FooterAbout from './FooterAbout';
import FooterContact from './FooterContact';
import FooterMarketing from './FooterMarketing';
import FooterProject from './FooterProject';
import FooterTitle from './FooterTitle';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import ContactSocial from './ContactSocial';

function Footer() {
	const { t } = useTranslation();

	return (
		<footer className="footer">
			<div className="container">
				<div className="footer__body">
					<FooterContact className="footer__contact">
						<FooterTitle className="footer__title">
							{t('footer.title')}
						</FooterTitle>
					</FooterContact>
					<FooterMarketing className="footer__marketing">
						<FooterTitle className="footer__title">
							{t('footer.marketing.title')}
						</FooterTitle>
					</FooterMarketing>
					<FooterProject className="footer__project">
						<FooterTitle className="footer__title">
							{t('footer.projects.title')}
						</FooterTitle>
					</FooterProject>
					<FooterAbout className="footer__about">
						<FooterTitle className="footer__title">
							{t('footer.about.title')}
						</FooterTitle>
					</FooterAbout>
					<ContactSocial className="footer__social" />
				</div>
			</div>
		</footer>
	);
}

export default Footer;
