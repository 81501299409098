import React from 'react'
import Management from './Management'
import BackgroundDecor from './BackgroundDecor'

function MainManagement() {
	return (
		<div className="main-management">
			<BackgroundDecor
				amountOfElements={16}
				className="main-management__background-decor center-left"
			/>
			<BackgroundDecor
				amountOfElements={16}
				className="main-management__background-decor top-right"
			/>
			<Management titleSize="big" />
		</div>
	)
}

export default MainManagement
