import useHttp from './httpService';

const useCategories = () => {
	const _baseUrl = `${process.env.REACT_APP_API_URL}`;
	const { getAll100 } = useHttp();
	const getAllCategories = (language = 'ua') => getAll100('category', 
	'GET',
	null,
	{'Accept-Language': `${language}`, 
	'X-Workspace': 'htp',
	'Access-Control-Allow-Origin': `${_baseUrl}`})
	return getAllCategories;
};

export default useCategories;
