import React, { RefObject, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface propsTitle {
	children: ReactNode;
	className?: string;
	size: 'big' | 'middle' | 'small';
	weight?: 'bold';
	refTitle?: RefObject<HTMLHeadingElement>;
	onClick?: () => void;
}

function Title({
	children,
	className,
	size,
	weight,
	refTitle,
	onClick,
}: propsTitle) {

	let location = useLocation();
	
		 if (((location.pathname === '/' || location.pathname === "/projects" || location.pathname === "/projects") && className !== 'projects-dropdown__title') || (location.pathname === '/about' && className !== 'about-info__title') || (location.pathname === '/services' && className !== 'services__title')) {
			return (
			<h2
				ref={refTitle}
				className={`title title--${size} title--${weight} ${className}`}
				onClick={onClick}
			>
				{children}
			</h2>)
		} else {
			return (
				<h1
					ref={refTitle}
					className={`title title--${size} title--${weight} ${className}`}
					onClick={onClick}
				>
					{children}
				</h1>)
		}
}

export default Title;
