import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks';
import useStore from '../store/selectors/useStore';
import ProjectsDropdownList from './ProjectsDropdownList';
import Title from './Title';
import '../utils/i18next';

function ProjectsDropdown() {
	const { activeCategory } = useStore();
	const refDropdown = useRef<HTMLDivElement>(null);
	const refTitle = useRef<HTMLHeadingElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	useOnClickOutside(refDropdown, handleCloseDropdown);

	function handleCloseDropdown() {
		setIsDropdownOpen(false);
	}

	return (
		<div
			ref={refDropdown}
			className={
				isDropdownOpen ? 'projects-dropdown open' : 'projects-dropdown'
			}
		>
			<Title
				refTitle={refTitle}
				size="middle"
				className="projects-dropdown__title"
				onClick={() => {
					isDropdownOpen ? setIsDropdownOpen(false) : setIsDropdownOpen(true);
				}}
			>
				{activeCategory?.name}
			</Title>

			<ProjectsDropdownList
				// refList={refDropdownList}
				closeDropdown={handleCloseDropdown}
				className={`projects-dropdown__list  ${refTitle.current && refTitle.current?.offsetWidth < 450
						? 'projects-dropdown__list--left-side'
						: ''}`} 
						refList={undefined}			/>
		</div>
	);
}

export default ProjectsDropdown;
