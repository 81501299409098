/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import BackgroundDecor from '../components/BackgroundDecor';
import NewsList from '../components/NewsList';
import Title from '../components/Title';
import useNews from '../services/service.news';
import useStore from '../store/selectors/useStore';
import { setNews } from '../store/slices/newsSlice';
import Pagination from '../components/Pagination';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function News() {
	const { t } = useTranslation();
	useDocumentTitle(t('news.title'));

	const { getAllNewsWithPagination } = useNews();
	const dispatch = useDispatch();
	const { news,language } = useStore();

	const [pagination, setPagination] = useState({
		currentPage: 0,
		lastPage: 0,
		total: 0,
	});
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const onPageChange = useCallback(
		(page: number) => {
			if(language) {
				getAllNewsWithPagination(page, language).then((data) => {
					const { articles, pagination } = data;
					if(pagination.lastPage < pagination.currentPage) {
						getAllNewsWithPagination(pagination.lastPage, language).then((correctData) => {
							dispatch(setNews(correctData.articles));
							setPagination(correctData.pagination);
							setIsVisible(true);
						})
					} else {
						dispatch(setNews(articles));
						setPagination(pagination);
						setIsVisible(true);
					}
				});
			}

		},
		[language]
	);

	return (
		<div className="news">
			<ScrollToTopOnMount />
			<div className="container">
				<div className="news__body">
					<BackgroundDecor
						amountOfElements={16}
						className="news__background-decor top-right"
					/>
					<BackgroundDecor
						amountOfElements={16}
						className="news__background-decor bottom-left"
					/>
					<Title size="middle" className="news__title">
						{t('news.title')}
					</Title>
					<div className="news__body">
						<NewsList data={news} />
						<Pagination
							maxPage={pagination.lastPage}
							onPageChange={onPageChange}
							isVisible={isVisible}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default News;
