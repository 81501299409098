import React, { ReactNode } from 'react';

interface propsButtonLink {
	children: ReactNode,
	className?: string,
	onClick?: () => void,
	type?: 'button' | 'submit' | 'reset',
	color: 'blue' | 'white'
}

function ButtonLink({ children, className, onClick, type, color }: propsButtonLink) {
	return (
		<button
			type={type}
			className={`button-link button-link--${color} ${className}`}
			onClick={onClick}
		>
			{children}
		</button>
	);
}

export default ButtonLink;
