import React from 'react';
import iconFacebook from '../img/icons/social/facebook.svg';
import iconInstagram from '../img/icons/social/instagram.svg';
import iconYoutube from '../img/icons/social/youtube.svg';
import iconGoogle from '../img/icons/social/google.svg';

interface propsContactSocial {
	className?: string;
}

function ContactSocial({ className }: propsContactSocial) {
	return (
		<ul className={`contact-social ${className}`}>
			<li className="contact-social__item">
				<a
					href="https://www.facebook.com/Virtual-Design-Bureau-Ukraine-106887037789802"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconFacebook}
						alt="facebook"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.instagram.com/ukrhydroproject/"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconInstagram}
						alt="instagram"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.youtube.com/channel/UCGb_695qQu15YUXLPIn9yNg"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconYoutube}
						alt="youtube"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.google.com/search?q=%D0%B3%D1%96%D0%B4%D1%80%D0%BE%D1%82%D0%B5%D1%85%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82&oq=%D0%B3%D1%96%D0%B4%D1%80&aqs=chrome.0.69i59j69i57j0i512l8.3083j0j4&sourceid=chrome&ie=UTF-8"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img src={iconGoogle} alt="google" className="contact-social__logo" />
				</a>
			</li>
		</ul>
	);
}

export default ContactSocial;
