import React, { useCallback, useState } from 'react';
import Title from './Title';
import AboutInfoText from './AboutInfoText';
import BackgroundDecor from './BackgroundDecor';
import ImageViewer from 'react-simple-image-viewer';

import сertificate from '../img/сertificates/document1.png';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function AboutInfo() {
	const [currentImage, _] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const openImageViewer = useCallback(() => {
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setIsViewerOpen(false);
	};

	const { t } = useTranslation();

	return (
		<section className="about-info">
			<div className="container">
				<Title size="middle" className="about-info__title">
					{t('about.title')}
				</Title>

				<div className="about-info__body">
					<BackgroundDecor
						amountOfElements={16}
						className="about-info__background-decor"
					/>
					<div
						onClick={() => openImageViewer()}
						className="about-info__slider about-info__swiper"
					>
						<img src={сertificate} alt="" />
					</div>
					<AboutInfoText className="about-info__text" />

					<ul className="about-info__list">
						<li onClick={() => openImageViewer()} className="about-info__item">
							<img src={сertificate} alt="" className="about-info__image" />
						</li>
					</ul>

					{isViewerOpen && (
						<ImageViewer
							src={[сertificate]}
							currentIndex={currentImage}
							disableScroll={true}
							closeOnClickOutside={true}
							onClose={closeImageViewer}
						/>
					)}
				</div>
			</div>
		</section>
	);
}

export default AboutInfo;
