import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationUA from './locales/ua/translation.json';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

const DETECTION_OPTIONS = {
	order: ['localStorage', 'navigator'],
	caches: ['localStorage'],
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'ua',
		debug: false,
		detection: DETECTION_OPTIONS,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			ua: {
				translation: translationUA,
			},
			en: {
				translation: translationEN,
			},
			ru: {
				translation: translationRU,
			},
		},
	});

export default i18n;
