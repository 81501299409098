import { createSlice } from '@reduxjs/toolkit';
import { languageState } from '../../typings/language';

const initialState: languageState = {
	language: '',
};

const languageSlice = createSlice({
	name: 'language',
	initialState,
	reducers: {
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
	},
});

const { actions, reducer: languageReducer } = languageSlice;
export const languageActions = actions;
export default languageReducer;
