import { createSlice } from '@reduxjs/toolkit';
import { categoriesState } from '../../typings/categories';

const initialState: categoriesState = {
	categories: {
		pagination: {
			total: 0,
			currentPage: 0,
			lastPage: 0,
		},
		categories: [],
	},
	activeCategory: {
		id: 0,
		name: '',
		category_ua: 'Категорії відсутні',
		category_ru: 'Категории отсутствуют',
		category_en: 'There are no categories',
		language: {
			code: ''
		},
		category: {
			id: ''
		}
	},
};

const categoriesSlice = createSlice({
	name: 'categories',
	initialState,
	reducers: {
		setCategories: (state, action) => {
			state.categories = action.payload;
		},
		setActiveCategory: (state, action) => {
			state.activeCategory = action.payload;
		},
	},
});

const { actions, reducer: categoriesReducer } = categoriesSlice;
export const categoriesActions = actions;
export default categoriesReducer;
