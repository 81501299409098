import React from 'react';
import Title from './Title';
import ServicesCard from './ServicesCard';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsServicesProjects {
	className?: string;
}

function ServicesProjects({ className }: propsServicesProjects) {
	const { t } = useTranslation();

	return (
		<section className={`services-projects ${className}`}>
			<Title size="small" className="services-projects__title">
				{t('services.project-work.title')}
			</Title>
			<ul className="services-projects__cards">
				<ServicesCard size="big" icon="1">
					{t('services.project-work.1')}
				</ServicesCard>
				<ServicesCard size="big" icon="2">
					{t('services.project-work.2')}
				</ServicesCard>
			</ul>
		</section>
	);
}

export default ServicesProjects;
