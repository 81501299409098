import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    title: ''
};

const titleSlice = createSlice({
    name: 'title',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
    }
});

const { actions, reducer: titleReducer } = titleSlice;
export const { setTitle } = actions;
export default titleReducer;