let sreenWidth = (project = false) => {
    let w = window.innerWidth;
    let screenSize = 'large'
    if (w < 600) {
        screenSize = 'small'
    }
    if (w > 600 && w < 1100) {
        screenSize = 'medium'
    }
    if(w > 1100 && project) {
        screenSize = 'original'
    }
    return `${screenSize}`
}
const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`;

export let sreenWidthVar = sreenWidth()
export let imageSrc = (item: any, project = false) => {
    if(item && project && window.innerWidth > 1100) {
        return `${_baseUrl}${item?.image['original']?.path}`
    }
    if(item) {
        return `${_baseUrl}${item?.image[sreenWidthVar]?.path}`
    }
    return ''
}
export let imageSrcProject = (project: any, thumnail = false) => {
    if(project) {
        let projectScreenWithVar = sreenWidth(thumnail)
        return `${_baseUrl}${project?.images[0]?.image[projectScreenWithVar]?.path}`
    }
    return ''
}