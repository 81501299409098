export const vacanciesOfferList = [
	{
		li_ua: 'Офіційне працевлаштування;',
		li_en: 'Formal employment;',
		li_ru: 'Официальное трудоустройство;',
	},
	{
		li_ua: 'Участь у великих міжнародних проектах;',
		li_en: 'Participation in major international projects;',
		li_ru: 'Участие в крупных международных проектах;',
	},
	{
		li_ua: 'Повний соціальний пакет та оплачувана відпустка/лікарняний;',
		li_en: 'Full social package and paid leave/sick leave;',
		li_ru: 'Полный социальный пакет и оплачиваемый отпуск/больничный;',
	},
	{
		li_ua:
			'5-денний робочий тиждень (8:30-17:30, в п’ятницю короткий день до 16:15);',
		li_en: '5-day work week (8: 30-17: 30, short day until 16:15 on Friday);',
		li_ru:
			'5-дневная рабочая неделя (8:30-17:30, в пятницу короткий день до 16:15);',
	},
	{
		li_ua: 'Своєчасну оплату праці;',
		li_en: 'Timely payment;',
		li_ru: 'Cвоевременную оплату труда;',
	},
	{
		li_ua: 'Медичне обслуговування;',
		li_en: 'Health care;',
		li_ru: 'Медицинское обслуживание;',
	},
	{
		li_ua:
			'Робота на сучасних прогресивних технологіях, в тому числі BIM-проектування;',
		li_en: 'Work on modern advanced technologies, including BIM-design;',
		li_ru:
			'Работа на современных прогрессивных технологиях, включая BIM-проектирование;',
	},
	{
		li_ua: 'Можливість навчання та підвищення кваліфікації;',
		li_en: 'Possibility of training and advanced training;',
		li_ru: 'Возможность обучения и повышения квалификации;',
	},
	{
		li_ua: 'Сучасний офіс в центрі Харкова (5 хв. від метро «Наукова»).',
		li_en:
			'Modern office in the center of Kharkiv (5 minutes from the metro "Scientific").',
		li_ru: 'Современный офис в центре Харькова (5 мин. от метро «Научная»).',
	},
];
