export const PAGINATION_ITEMS_PER_VIEW = 4;
export const RECORDS_PER_PAGE = 10;
export const FIRST_PAGE_NUMBER = 1;
export const INITIAL_MAX_PAGE = 0;

export const PROJECT_INSIDE = {
	category_en: '',
	category_ru: '',
	category_ua: '',
	content_en: '',
	content_ru: '',
	content_ua: '',
	country_en: '',
	country_ru: '',
	country_ua: '',
	id: 0,
	images: [],
	lat: 0,
	lng: 0,
	site_name: '',
	table_en: '',
	table_ru: '',
	table_ua: '',
	thumbnail: '',
	title_en: '',
	title_ru: '',
	title_ua: '',
};

export const INITIAL_NEWS = {
	image: '',
	created_at: {
		date: '',
	},
	title_ua: '',
	content_ua: '',
	site_name: '',
	id: 0,
	is_pinned: false,
};
