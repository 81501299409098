import React from 'react';
import ContactInfo from './ContactInfo';
import HeaderLanguage from './HeaderLanguage';
import HeaderNavigation from './HeaderNavigation';
import Map from './Map';

interface propsContactDropdown {
	className?: string,
	closeDropdown: () => void,
	isOpenDropdown: boolean,
}

function ContactDropdown({ className, closeDropdown, isOpenDropdown }: propsContactDropdown) {
	return (
		<div
			className={isOpenDropdown ? `contact-dropdown open ${className}` : `contact-dropdown ${className}`}>
			<button
				type="button"
				className="contact-dropdown__close"
				onClick={() => closeDropdown()}
			/>
			<div className="contact-dropdown__body">
				<div className="contact-dropdown__body-top">
					<HeaderLanguage
						className="contact-dropdown__language"
					/>
					<HeaderNavigation
						closeDropdown={closeDropdown}
						className="contact-dropdown__navigation"
					/>
					<Map className="contact-dropdown__map" />
				</div>
				<ContactInfo className="contact-dropdown__info" />
			</div>
		</div>
	);
}

export default ContactDropdown;
