import React from 'react';
import Title from './Title';
import { Link } from 'react-router-dom';
import BackgroundDecor from './BackgroundDecor';
import notFound from '../img/404.svg';
import notFoundBackground from '../img/404Background.svg';
import { MAIN } from '../helpers/routes';
import { useDocumentTitle } from '../hooks';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const decorElements = [
	{
		amountOfElements: 48,
		position: 'top-left',
	},
	{
		amountOfElements: 16,
		position: 'top-right',
	},
	{
		amountOfElements: 16,
		position: 'bottom-left',
	},
	{
		amountOfElements: 16,
		position: 'bottom-right',
	},
];

function NotFound() {
	const { t } = useTranslation();

	useDocumentTitle(t('404.error'));

	return (
		<section className="not-found">
			<div className="not-found__background left">
				<img src={notFoundBackground} alt="" />
			</div>
			<div className="not-found__background right">
				<img src={notFoundBackground} alt="" />
			</div>

			{decorElements.map((element, index) => (
				<BackgroundDecor
					key={index}
					amountOfElements={element.amountOfElements}
					className={`not-found__background-decor ${element.position}`}
				/>
			))}

			<div className="not-found__body">
				<div className="not-found__image">
					<img src={notFound} alt="" />
				</div>
				<Title size="middle" className="not-found__title">
					{t('404.error')}
				</Title>
				<Link
					to={MAIN}
					className="not-found__button button-link button-link--blue"
				>
					{t('404.to-main')}
				</Link>
			</div>
		</section>
	);
}

export default NotFound;
