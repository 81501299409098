import React from 'react';
import MainAboutInfo from './MainAboutInfo';
import MainAboutSlider from './MainAboutSlider';
import MainAboutSpoilers from './MainAboutSpoilers';
import MainAboutTabs from './MainAboutTabs';

function MainAbout() {
	return (
		<section className="main-about">
			<div className="container">
				<MainAboutSlider />
				<MainAboutInfo />
				<MainAboutTabs />
				<MainAboutSpoilers />
			</div>
		</section>
	);
}

export default MainAbout;
