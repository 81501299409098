import React from 'react';
import MapSVG from './MapSVG';
import Title from './Title';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function MainMap() {
	const { t } = useTranslation();

	return (
		<section className="main-map">
			<div className="container">
				<div className="main-map__body">
					<div className="main-map__info">
						<Title size="big" className="main-map__title">
							{t('main.map.title')}
						</Title>
						<div className="main-map__description">
							{t('main.map.description.first-part')}
						</div>
						<div className="main-map__country">
							{t('main.map.description.second-part')}
						</div>
					</div>
					<MapSVG className="main-map__world" />
				</div>
			</div>
		</section>
	);
}

export default MainMap;
