import React from 'react';
import Title from './Title';
import ManagementInfo from './ManagementInfo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { management } from '../data/management';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsManagement {
	titleSize: 'big' | 'middle';
}

const settings = {
	slidesPerView: 1,
	centeredSlides: false,
	loop: false,
	speed: 500,
	pagination: {
		clickable: true,
		bulletElement: 'management__bullet',
		bulletClass: 'management__bullet',
		bulletActiveClass: 'management__bullet--active',
		clickableClass: 'management__pagination',
	},
};

function Management({ titleSize }: propsManagement) {
	const { t } = useTranslation();

	const managementData = t('management.managementData', {
		returnObjects: true,
	});

	return (
		<section className="management">
			<div className="container">
				<div id="management" className="management__body">
					<Title size={titleSize} className="management__title">
						{t('management.title')}
					</Title>

					<ul className="management__list">
						{Array.from(managementData).map((item: any, index) => (
							<ManagementInfo
								key={index}
								className="management__item"
								photo={management[index].photo}
								fullName={item.fullName}
								position={item.position}
							/>
						))}
					</ul>

					<Swiper
						{...settings}
						modules={[Pagination, Autoplay]}
						className={`management__list management__list--mobile`}
					>
						{Array.from(managementData).map((item: any, index) => (
							<SwiperSlide key={index}>
								<ManagementInfo
									key={index}
									className="management__item"
									photo={management[index].photo}
									fullName={item.fullName}
									position={item.position}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
}

export default Management;
