import React from 'react';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsContactButton {
	className?: string;
	openDropdown: () => void;
}

function ContactButton({ openDropdown, className }: propsContactButton) {
	const { t } = useTranslation();

	return (
		<button
			type="button"
			className={`contact-button ${className}`}
			onClick={() => openDropdown()}
		>
			{t('contact')}
		</button>
	);
}

export default ContactButton;
