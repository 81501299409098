import { useNavigate } from 'react-router-dom';

const useRedirectToItem = () => {
	const navigate = useNavigate();

	const redirect = (page: string, itemId?: number) => {
		navigate(itemId ? `${page}/${itemId}` : `${page}`);
	};

	return redirect;
};

export default useRedirectToItem;
