import React from 'react';
import { Link } from 'react-router-dom';
import { propsProjectsCountryItem } from '../typings/projects';
import projectEmptyPhoto from '../img/projects/project-empty-photo.png';
import ArrowLink from './ArrowLink';
import '../utils/i18next';
import { imageSrcProject } from '../helpers/imageSrc';

function ProjectsCountryItem({ data }: propsProjectsCountryItem) {

	return (
		<>
			{data.map((project, index) => (
				<li key={index} className="projects-country-item">
					<Link className="projects-country-item__link" to={`${project.slug}`}>
						<div className="projects-country-item__image">
						{project?.images[0]?.image?.original?.path ? (
								<img
									src={imageSrcProject(project) || projectEmptyPhoto}
									alt={project?.title}
								/>
							) : (
								<span className="projects-country-item__no-image" />
							)}
						</div>
						<h4 className="projects-country-item__title">
							{project.title}
						</h4>
						<ArrowLink className="projects-country-item__arrow" />
					</Link>
				</li>
			))}
		</>
	);
}

export default ProjectsCountryItem;
