import { createSlice } from "@reduxjs/toolkit";
import { documentsState } from "../../typings/documents";

const initialState: documentsState = {
    documents: [],
    siteDocument: []
};

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setDocuments: (state, action) => {
            state.documents = action.payload;
        },
        setSiteDocument: (state, action) => {
            state.siteDocument = action.payload;
        }
    }
});

const { actions, reducer: documentsReducer } = documentsSlice;
export const documentsActions = actions;
export default documentsReducer;