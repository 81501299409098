import React from 'react';
import { document } from '../typings/documents';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsAboutFileItem {
	className?: string;
	document: document;
}

function AboutFileItem({ className, document }: propsAboutFileItem) {
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`
	const { t } = useTranslation();

	return (
		<li
			className={`about-file-item ${className}`}
			title={document.name}
		>
			<h6 className="about-file-item__title">
				<div className="about-file-item__truncate">
					{document.name}
				</div>
			</h6>
			<a
				download
				target='_blank'
				rel="noreferrer"
				href={`${_baseUrl}${document?.file?.path}`}
				className="about-file-item__button button-link button-link--blue"
			>
				{t('about.buttons.download')}
			</a>
		</li>
	);
}

export default AboutFileItem;
