import React from 'react';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';

function MainAboutInfo() {
	const { t } = useTranslation();
	const { language } = useStore();

	return (
		<div className="main-about-info">
			<div className="main-about-info__body">
				<div className="main-about-info__block-title">
					{language !== 'en' ? (
						<>
							<span className="main-about-info__subtitle">
								{t('main.title.type')}
							</span>
							<h1 className="main-about-info__title">{t('main.title.name')}</h1>
						</>
					) : (
						<>
							<h1
								className={`main-about-info__title ${
									language === 'en' ? 'main-about-info__title--pt' : ''
								}`}
							>
								{t('main.title.name')}
							</h1>
							<span className="main-about-info__subtitle">
								{t('main.title.type')}
							</span>
						</>
					)}
					<div
						className={`main-about-info__description ${
							language === 'en' ? 'main-about-info__description--mt' : ''
						}`}
					>
						{t('main.description')}
					</div>
				</div>
				<div className="main-about-info__location">
					{t('main.city.first-part')}
					<br />
					{t('main.city.second-part')}
				</div>
			</div>
		</div>
	);
}

export default MainAboutInfo;
