import React, { useState } from 'react';
import { languageActions } from '../store/slices/languageSlice';

import { useTranslation } from 'react-i18next';
import useBindActionCreators from '../hooks/useBindActionCreators';
import useStore from '../store/selectors/useStore';
import '../utils/i18next';

interface propsHeaderLanguage {
	className?: string;
}

const languages = [
	{
		to: '/',
		name: 'УКР',
		language_type: 'ua',
	},
	{
		to: '/',
		name: 'РУС',
		language_type: 'ru',
	},
	{
		to: '/',
		name: 'ENG',
		language_type: 'en',
	},
];

function HeaderLanguage({ className }: propsHeaderLanguage) {
	const { language: languageStore } = useStore();

	const [_, setIsLanguageActive] = useState(0);
	const boundCreators = useBindActionCreators({ ...languageActions });
	const { setLanguage } = boundCreators;

	const { t, i18n } = useTranslation();

	const changeLanguage = (language: string, index: number) => {
		setIsLanguageActive(index);
		i18n.changeLanguage(language);
		setLanguage(language);
		if(language === 'ua') {
			document.documentElement.setAttribute('lang', 'uk');
		} else {
			document.documentElement.setAttribute('lang', language);
		}
	};

	return (
		<div className={`header-language ${className}`}>
			<ul className="header-language__list">
				{languages.map((language, index) => (
					<li key={index} className="header-language__item">
						<button
							type="button"
							className={
								language.language_type === languageStore
									? 'header-language__link active'
									: 'header-language__link'
							}
							onClick={() => changeLanguage(language.language_type, index)}
						>
							{language.name}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}

export default HeaderLanguage;
