import React from 'react'

interface propsBackgroundDecor {
	className?: string,
	amountOfElements: number,
}

function BackgroundDecor({ amountOfElements, className }: propsBackgroundDecor) {

	let elements = [] as number[];
	for (let i = 1; i <= amountOfElements; i++) {
		elements.push(i);
	}

	return (
		<ul
			aria-hidden="true"
			className={`background-decor ${className}`}
		>
			{elements.map((item, index) => (
				<li key={index} className="background-decor__item" />
			))}
		</ul>
	)
}

export default BackgroundDecor
