import React from 'react';
// import { propsTable } from '../typings/projects';

interface propsTable {
	data?: {
		key: string;
		value: string;
	}[];
}

function Table({ data }: propsTable) {
	return (
		<div className="table">
			{data?.map((item, index) => (
				<div className="table__row" key={index}>
					<h6 className="table__col table__col--title">
						{item?.key}
					</h6>
					<span className="table__col table__col--info">
						{item?.value}
					</span>
				</div>
			))}
		</div>
	);
};

export default Table;
