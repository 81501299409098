import React from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import AboutFile from '../components/AboutFile';
import AboutInfo from '../components/AboutInfo';
import Management from '../components/Management';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function About() {
	const { t } = useTranslation();
	useDocumentTitle(t('about.title'));

	return (
		<div className="about">
			<ScrollToTopOnMount />
			<div className="about__body">
				<AboutInfo />
				<Management titleSize="middle" />
				<AboutFile />
			</div>
		</div>
	);
}

export default About;
