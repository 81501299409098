import { useSelector } from 'react-redux';
import { stateType } from '../../typings/state';

const useStore = () => {
	const state = useSelector((state: stateType) => state);
	const vacancies = state.vacancies.vacancies;
	const news = state.news.news;
	const { categories, activeCategory } = state.categories;
	const { countries, activeCountry, filteredCountries } = state.countries;
	const projects = state.projects.filteredProjects;
	const { documents, siteDocument } = state.documents;
	const language = state.language.language;
	const contactDropdown = state.contactDropdown.isOpen;
	const title = state.title.title;

	return {
		vacancies,
		news,
		categories,
		countries,
		activeCountry,
		filteredCountries,
		projects,
		activeCategory,
		documents,
		siteDocument,
		language,
		contactDropdown,
		title
	};
};

export default useStore;
