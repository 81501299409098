import React, { ReactNode } from 'react';

interface propsFooterTitle {
	className?: string,
	children?: ReactNode
}

function FooterTitle({ children, className }: propsFooterTitle) {
	return (
		<h6 className={`footer-title ${className}`}>
			{children}
		</h6>
	);
}

export default FooterTitle;
