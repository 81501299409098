import React, { ReactNode } from 'react';

interface propsProjectsCountryList {
	children?: ReactNode,
	className?: string,
}

function ProjectsCountryList({ children, className }: propsProjectsCountryList) {
	return (
		<ul className={`projects-country-list ${className}`}>
			{children}
		</ul>
	);
}

export default ProjectsCountryList;
