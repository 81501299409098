import { createSlice } from "@reduxjs/toolkit";
import { countries } from "../../typings/countries";

const defaultFilter = {
    id: 0,
    name: '',
    country_en: 'All',
    country_ua: 'Усі',
    country_ru: 'Все',
    country: {
        id: 0
    }
};

const initialState: countries = {
    countries: [],
    filteredCountries: [],
    activeCountry: 0
};

const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = [defaultFilter, ...action.payload];
            state.filteredCountries = [defaultFilter, ...action.payload];
        },
        setActiveCountry: (state, action) => {
            state.activeCountry = action.payload;
            if (action.payload !== 0) {
                state.filteredCountries = state.countries.filter(country => country?.country?.id === action.payload);
            } else {
                state.filteredCountries = state.countries;
            }
        },
        resetActiveCountry: (state) => {
            state.activeCountry = defaultFilter.id;
        }
    }
});

const { actions, reducer: countriesReducer } = countriesSlice;
export const { setCountries, setActiveCountry, resetActiveCountry } = actions;
export default countriesReducer;