import React from 'react';
import Title from './Title';
import ServicesCard from './ServicesCard';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsServicesEngineering {
	className?: string;
}

function ServicesEngineering({ className }: propsServicesEngineering) {
	const { t } = useTranslation();

	return (
		<section className={`services-engineering ${className}`}>
			<Title size="small" className="services-engineering__title">
				{t('services.engineering-work.title')}
			</Title>
			<ul className="services-engineering__cards">
				<ServicesCard size="middle" icon="3">
					{t('services.engineering-work.1')}
				</ServicesCard>
				<ServicesCard size="middle" icon="4">
					{t('services.engineering-work.2')}
				</ServicesCard>
				<ServicesCard size="middle" icon="5">
					{t('services.engineering-work.3')}
				</ServicesCard>
				<ServicesCard size="middle" icon="6">
					{t('services.engineering-work.4')}
				</ServicesCard>
			</ul>
		</section>
	);
}

export default ServicesEngineering;
